import Offcanvas from "react-bootstrap/Offcanvas";
import styles from "./EditUser.module.css";
import { useEffect, useState } from "react";
import ImageModal from "./ImageModal";
import useToast from "../../../utils/toast/useToast";
import { userLogout } from "../../../services/user";
import user from "../../../assets/profile.png";
import { FaRegUser } from "react-icons/fa6";
import { MdOutlinePhone } from "react-icons/md";
import { MdOutlineMail } from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";
import { TbLogout } from "react-icons/tb";
import { motion } from "framer-motion";
import CommonModal from "./CommonModel";
import LoginPage from "../../../pages/LoginPage/LoginPage";
import OtpVerify from "../../../pages/OtpVerify";
import { getUserNameInitials } from "../../../constants";

const EditUser = ({
  handleClose,
  showSidebar,
  showLogin,
  setShowLogin,
  setGuestId,
  guestUserId,
}) => {
  const toast = useToast();
  const location = useLocation();
  const navigate = useNavigate();
  const userData = JSON.parse(localStorage.getItem("userDetails"));
  const [userLogged, setUserLogged] = useState(false);
  const guestId = localStorage.getItem("guestUserId");
  const [showConfirmLogout, setShowConfirmLogout] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const handleClickLogout = () => {
    setShowConfirmLogout(!showConfirmLogout);
  };
  useEffect(() => {
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    if (
      location &&
      location?.search &&
      location.search?.includes("logged") &&
      userDetails
    ) {
      setUserLogged(true);
    } else if (userDetails) {
      setUserLogged(true);
    }
  }, [location]);
  const handleLogOut = async () => {
    const response = await userLogout(toast);
    localStorage.removeItem("userDetails");
    localStorage.removeItem("latestOrder");
    localStorage.removeItem("token");
    // toast.showSuccessToast("Logged out successfully");
    window.location.replace("/home");
    // Cookies.remove("token");
  };
  return (
    <>
      {" "}
      <Offcanvas
        show={showSidebar}
        onHide={handleClose}
        className="w-500"
        style={{ width: "70%", maxWidth: "70%" }}
      >
        <Offcanvas.Header closeButton></Offcanvas.Header>
        <Offcanvas.Body className="text-center mt-1 overflow-hidden">
          {showConfirmLogout && (
            <CommonModal
              handleClose={handleClickLogout}
              btn2Text={"Log Out"}
              visible={true}
              btn2OnClick={handleLogOut}
              centered={true}
              contentStyle={{
                paddingBottom: "30px",
                paddingTop: "30px",
                fontWeight: 600,
                fontSize: "20px",
                textAlign: "center",
              }}
              FooterStyle={{
                paddingBottom: "20px",
                paddingTop: "20px",
                justifyContent: "space-evenly",
                border: "none",
              }}
              btn1Style={{ padding: "10px", width: "100px" }}
              btn2Style={{ padding: "10px", width: "100px" }}
            >
              Are you sure you want to logout?
            </CommonModal>
          )}
          {userLogged ? (
            // If user is logged in, display user information
            <div className={styles.sidebarInside}>
              <img
                src={
                  (userData && userData.profileImage) ||
                  getUserNameInitials(userData && userData.userName)
                }
                alt=""
                className={`${styles.user}`}
                onClick={openModal}
                style={{ cursor: "pointer" }}
              />
              <ImageModal
                showModal={showModal}
                imageUrl={
                  (userData && userData.profileImage) ||
                  getUserNameInitials(userData && userData.userName)
                }
                closeModal={closeModal}
              />
              <div className={styles.userdetail}>
                <div className={styles.userinformation}>
                  <FaRegUser className={styles.icons} />
                  <span>{userData && userData.userName}</span>
                </div>
                <hr />
                <div className={styles.userinformation}>
                  <MdOutlinePhone className={styles.icons} />
                  <span>{userData && userData.phoneNumber}</span>
                </div>
                <hr />
                <div className={styles.userinformation}>
                  <MdOutlineMail className={styles.icons} />
                  <span>
                    {userData && userData.email ? userData.email : ""}
                  </span>
                </div>
                <hr />
              </div>
              <motion.button
                className={styles.editProfile}
                onClick={() => {
                  navigate("/profile", { state: userData });
                }}
                whileTap={{ scale: 0.9 }}
              >
                EDIT PROFILE
              </motion.button>
              <div className={styles.logout}>
                <hr />
                <div className="d-flex flex-row justify-content-center align-items-center">
                  <TbLogout onClick={() => handleClickLogout()} />
                  <span className="px-3" onClick={() => handleClickLogout()}>
                    Log Out
                  </span>
                </div>
              </div>
            </div>
          ) : (
            // If user is not logged in, display login options
            <div className={styles.sidebarLogin}>
              <img src={user} alt="" className={styles.user} />
              <div className={`mt-1`}>Welcome, Guest User</div>
              <motion.button
                onClick={() => {
                  setShowLogin("login");
                  handleClose();
                }}
                className={styles.loginbtn}
                whileTap={{ scale: 0.9 }}
              >
                LOGIN
              </motion.button>
            </div>
          )}
        </Offcanvas.Body>
      </Offcanvas>{" "}
      {showLogin === "login" ? (
        <LoginPage
          setShowLogin={setShowLogin}
          showLogin={showLogin}
          setGuestId={setGuestId}
          guestUserId={guestUserId}
        />
      ) : null}
      {showLogin === "otpverify" ? (
        <OtpVerify setShowLogin={setShowLogin} showLogin={showLogin} />
      ) : null}
    </>
  );
};

export default EditUser;
